<template>
  <br /><br /><br /><br />
  <Section
    class="flex flex-col justify-center md:flex-row md:justify-between items-center px-5 md:px-36 lg:px-64 pt-16"
    v-smartnav
  >
    <div class="w-full md:w-1/2 h-full relative">
      <br />
      <div class="w-9/12 h-72 absolute bottom-1/3 left-1/4 z-0">
        <img src="../assets/images/kidsbythewindow.jpg" alt="" class="w-full h-full object-cover" />
      </div>
      <div
        class="w-10/12 md:w-full h-10 md:h-28 shadow-xl border-8 border-white absolute top-1/4 md:top-1/3 left-11 z-0"
      ></div>
      <div class="w-9/12 h-72 shadow-xl bg-green-800 z-30"></div>
      <br /><br />
    </div>

    <div class="w-full md:w-1/2 md:ml-20 text-left">
      <h4 class="font-bold text-4xl capitalize text-green-800 leading-tight">
        Every Human Being Has Rights
      </h4>
      <br />

      <p class="text-gray-800 text-justify">
        The rights of all people worldwide are protected by three documents
        created by the United Nations: the Universal Declaration of Human
        Rights,(UDHR)the International Covenant on Economic, Social and Cultural
        Rights(ICESCR)and the International Covenant on Civil and Political
        Rights(ICCPR)Together, these three documents form the International Bill
        of Human Rights.
      </p>

      <br />
    </div>
  </Section>

  <br />
  <div class="px-5 md:px-36 lg:px-64 text-justify md:text-center">
    <div class="w-full md:w-9/12 m-auto">
      <p class="text-gray-800 text-justify">
        Malawi has signed and ratified all three documents, which means that it
        is legally bound by the provisions of the two Covenants. Other convents
        to which Malawi is a signatory are the ones aimed at promoting the
        rights of women, The International Covenant on the Elimination of All
        forms of Discrimination against Women(CEDAW) and the Convention on the
        Rights of the Child(CRC) Because Africa has specific issues and
        challenges, the African Union has created its own document, the African
        Charter on Human and People's Rights(also known as the Banjul Charter).
      </p>

      <br />
      <p class="text-gray-800 text-justify">
        The charter functions similarly to an international treaty, and the
        African Union has set up the African Court on Human and Peoples' Rights
        to uphold its provisions. International law is complicated and very
        difficult to enforce, so it is important for countries to provide for
        human rights in their own laws. The Constitution of Malawi is the
        highest law in the country; it governs the way in which all other laws
        are made, and it prevails if there is a disagreement between it and
        another legal instrument. Certain parts of the constitution can only be
        changed by referendum- which means the people get a chance to vote. This
        is an important protection against excessive or irresponsible use of
        government power. Chapter five of the Constitution details your
        constitutional rights.
      </p>

      <br />
      <p class="text-gray-800 text-justify">
        There are some inconsistencies between the international laws on human
        rights and the Constitution. Some of these are rights that have been
        left out of the constitution because Malawi does not yet have the
        resources to achieve them. Others are limitations and reservations
        placed on your constitutional rights in the interests of making it
        easier to govern. These are limitations that the Malawian government has
        decided to impose, and in some case, restrict the ability of Malawians
        to exercise their human rights. Human rights law in Malawi is further
        complicated by customary law, which is given status by the Constitution.
      </p>

      <br />
      <p class="text-gray-800 text-justify">
        Customary law is the body of law created by cultural habit and
        tradition, and is often unwritten; Organizations like the Rights
        Institute for Social Empowerment-RISE work to encourage governments to
        bring their laws into line with the International Bill of Human Rights
        for the betterment of all people in Malawi.
      </p>

      <br />
      <p class="text-gray-800 text-justify">
        <b class="text-green-800">Human Rights Made Easy</b>
        &nbsp;
        <i
          >"The rights of every person are diminished when the rights of one
          person are threatened." - John F. Kennedy "It is undeniable that every
          human being is entitled to living space, daily bread, and the
          protection of the law as a common birthright; these are fundamentals
          and should not be handed out as an act of charity."</i
        >
        - Alfred Delp, S.J.
      </p>

      <br />
      <h4 class="text-green-800 text-justify text-2xl font-bold">
        Universal, Inalienable Human Rights.
      </h4>
      <p class="text-gray-800 text-justify">
        What are human rights? Human rights are rights inherent to all human
        beings, whatever our nationality, place of residence, sex, national or
        ethnic origin, color, religion, language, or any other status. We are
        all equally entitled to our human rights without discrimination. These
        rights are all interrelated, interdependent and indivisible. Universal
        human rights are often expressed and guaranteed by law, in the forms of
        treaties, customary international law , general principles and other
        sources of international law. International human rights law lays down
        obligations of Governments to act in certain ways or to refrain from
        certain acts, in order to promote and protect human rights and
        fundamental freedoms of individuals or groups.
      </p>

      <br />
      <p class="text-gray-800 text-justify">
        The principle of universality of human rights is the cornerstone of
        international human rights law. This principle, as first emphasized in
        the Universal Declaration on Human Rights in 1948, has been reiterated
        in numerous international human rights conventions, declarations, and
        resolutions. The 1993 Vienna World Conference on Human Rights, for
        example, noted that it is the duty of States to promote and protect all
        human rights and fundamental freedoms, regardless of their political,
        economic and cultural systems. All States have ratified at least one,
        and 80% of States have ratified four or more, of the core human rights
        treaties, reflecting consent of States which creates legal obligations
        for them and giving concrete expression to universality. Some
        fundamental human rights norms enjoy universal protection by customary
        international law across all boundaries and civilizations. Human rights
        are inalienable. They should not be taken away, except in specific
        situations and according to due process. For example, the right to
        liberty may be restricted if a person is found guilty of a crime by a
        court of law.
      </p>

      <br />
      <h4 class="text-green-800 text-left md:text-justify font-bold text-2xl">
        Interdependent and indivisible
      </h4>
      <p class="text-gray-800 text-justify">
        All human rights are indivisible, whether they are civil and political
        rights, such as the right to life, equality before the law and freedom
        of expression; economic, social and cultural rights, such as the rights
        to work, social security and education, or collective rights, such as
        the rights to development and self-determination, are indivisible,
        interrelated and interdependent. The improvement of one right
        facilitates advancement of the others. Likewise, the deprivation of one
        right adversely affects the others.
      </p>

      <br />
      <h4 class="text-green-800 text-justify font-bold text-2xl">
        Equal and non-discriminatory
      </h4>
      <p class="text-gray-800 text-justify">
        Non-discrimination is a cross-cutting principle in international human
        rights law. The principle is present in all the major human rights
        treaties and provides the central theme of some of international human
        rights conventions such as the International Convention on the
        Elimination of All Forms of Racial Discrimination and the Convention on
        the Elimination of All Forms of Discrimination against Women. The
        principle applies to everyone in relation to all human rights and
        freedoms and it prohibits discrimination on the basis of a list of
        non-exhaustive categories such as sex, race, color and so on. The
        principle of non-discrimination is complemented by the principle of
        equality, as stated in Article 1 of the Universal Declaration of Human
        Rights: "All human beings are born free and equal in dignity and
        rights."
      </p>

      <br />
      <h4 class="text-green-800 text-justify font-bold text-2xl">
        Both Rights and Obligations
      </h4>
      <p class="text-gray-800 text-justify">
        Human rights entail both rights and obligations. States assume
        obligations and duties under international law to respect, to protect
        and to fulfill human rights. The obligation to respect means that States
        must refrain from interfering with or curtailing the enjoyment of human
        rights. The obligation to protect requires States to protect individuals
        and groups against human rights abuses. The obligation to fulfill means
        that States must take positive action to facilitate the enjoyment of
        basic human rights. At the individual level, while we are entitled our
        human rights, we should also respect the human rights of others.
      </p>

      <br />
      <br />
      <div class="text-left">
        <p class="text-gray-500">Learn More</p>
        <router-link to="clinic" class="text-green-600">
          Human Rights Clinc
        </router-link>
        <span class="text-gray-600">|</span>
        <router-link to="articles" class="text-green-600">
          Human Rights Articles
        </router-link>
      </div>
      <br>
    </div>
  </div>
</template>

<script>
export default {
  name: "Donate",
};
</script>

<style scoped>
.landing-parallax {
  /* The image used */
  background-image: url("../assets/images/hands.jpg");

  /* Set a specific height */
  height: 100vh;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
